import React from 'react'

type Props = {
    subscriber?: any
}

export const Data: React.FC <Props> = ({subscriber}) => {
    const info = subscriber
    return (
        <div style={{color:'green', fontWeight:'bold', fontSize: '16px'}}> (<span style={{color:'red'}}>{info.credit}</span>) - {info.full_name}  :סה״כ שטיפות שיש למנוי</div>
    )
}