import { APIService } from "services";

export const getUsers = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllUsers(pageNo, pageSize);
    }
    else {
        try {
            res = await searchUsers(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], totalCount: 0 }
        }
    }
    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addUsers = (data) => {
    return APIService.api().post(`/Users`, data)
}
export const updateUsers = (user_id, data) => {
    return APIService.api().patch(`/Users/${user_id}`, data)
}
export const getAllUsers = (pageNo, pageSize) => {
    return APIService.api().get(`/Users/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneUsers = (user_id) => {
    return APIService.api().get(`/Users/${user_id}`)
}
export const searchUsers = (searchKey, pageNo, pageSize) => {
    return APIService.api().get(`/Users/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteUsers = (user_id) => {
    return APIService.api().delete(`/Users/${user_id}`)
}
