import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setSubscribersWashMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addSubscriberswash, updateSubscribersWash } from "services/SubscribersWashService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const SubscribersWashForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { action_id: '', subscriber_id: '', car_number: '', date_of_wash: '' };
    const initialValue = action === 'edit' ? row : iValue;


    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateSubscribersWash(values.action_id, values);
                if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setSubscribersWashMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setSubscribersWashMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addSubscriberswash(values);
                if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setSubscribersWashMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setSubscribersWashMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
            subscriber_id: yup.number().required('subscriber_id is required'),
            car_number: yup.string().required('car_number is required'),
            date_of_wash: yup.date().required('date_of_wash is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Subscriberswash
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Subscriberswash</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group>
                        <label className="form -control-label">subscriber_id</label>
                        <Form.Control type="number" name="subscriber_id" className="form-control" value={formik.values.subscriber_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.subscriber_id && !!formik.errors.subscriber_id}
                            isValid={!!formik.touched.subscriber_id && !formik.errors.subscriber_id}
                        ></Form.Control>
                        {
                            formik.errors.subscriber_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.subscriber_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">מס רכב</label>
                        <Form.Control type="text" name="car_number" className="form-control" value={formik.values.car_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.car_number && !!formik.errors.car_number}
                            isValid={!!formik.touched.car_number && !formik.errors.car_number}
                        ></Form.Control>
                        {
                            formik.errors.car_number && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.car_number}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">ת. שטיפה</label>
                        <Form.Control type="date" name="date_of_wash" className="form-control" value={formik.values.date_of_wash}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.date_of_wash && !!formik.errors.date_of_wash}
                            isValid={!!formik.touched.date_of_wash && !formik.errors.date_of_wash}
                        ></Form.Control>
                        {
                            formik.errors.date_of_wash && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.date_of_wash}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">שמור</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

