import { APIService } from "services";

export const getSubscribers = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAll(pageNo, pageSize);
    }
    else {
        try {
            res = await searchSubscribers(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], totalCount: 0 }
        }
    }
    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 }
    }

}
export const getSubscriberWash = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllSubscribers(pageNo, pageSize);
    }
    else {
        try {
            res = await searchSubscriberWash(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], totalCount: 0 }
        }
    }
    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addSubscribers = (data) => {
    return APIService.api().post(`/Subscribers`, data)
}
export const updateSubscribersWash = (subscriber_id) => {
    return APIService.api().patch(`/SubscribersWash/${subscriber_id}`)
}
export const updateSubscribers = (subscriber_id, data) => {
    console.log('data first:',data)
    return APIService.api().patch(`/Subscribers/upd/${subscriber_id}`, data)
}
export const getAll = (pageNo, pageSize) => {
    return APIService.api().get(`/Subscribers/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getAllSubscribers = (pageNo, pageSize) => {
    return APIService.api().get(`/Subscribers/wash/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneSubscribers = (subscriber_id) => {
    return APIService.api().get(`/Subscribers/${subscriber_id}`)
}
export const searchSubscribers = (searchKey, pageNo, pageSize) => {
    return APIService.api().get(`/Subscribers/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const searchSubscriberWash = (searchKey, pageNo, pageSize) => {
    return APIService.api().get(`/Subscribers/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getAllSubscribersWash = (pageNo, pageSize) => {
    return APIService.api().get(`/SubscribersWash/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const updateRenew = (subscriber_id, data) => {
    //console.log("data ::", data)
    return APIService.api().patch(`/Subscribers/${subscriber_id}`, data)
}
export const updateCredit = (subscriber_id) => {
    return APIService.api().patch(`/Subscribers/credit/${subscriber_id}`)
}
export const findByCarNumber = (car_number) => {
    return APIService.api().get(`/Subscribers/car/${car_number}`)
}
export const findByPhone = (phone) => {
    return APIService.api().get(`/Subscribers/phone/${phone}`)
}

export const deleteSubscribers = (subscriber_id) => {
    return APIService.api().delete(`/Subscribers/${subscriber_id}`)
}
