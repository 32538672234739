export const MenuItems = [
    { title: 'ראשי', path: '/dashboard', icon: 'fas fa-sitemap', subMenu: [] },
    //{ title: 'File Upload', path: '/upload', icon: 'fas fa-fw fa-upload', subMenu: [] },
    { title: 'משתמשי מערכת', path: '/Users', icon: 'fas fa-user-cog', subMenu: [] },
    { title: 'מנויים', path: '/Subscribers', icon: 'fas fa-user-circle', subMenu: [] },
    { title: 'שטיפה מנויים', path: '/Wash', icon: 'fas fa-wind', subMenu: [] },
    { title: 'חברות', path: '/Company', icon: 'fas fa-building', subMenu: [] },
    { title: 'שטיפה חברות', path: '/CompanyWashList', icon: 'fas fa-wind', subMenu: [] },
    { title: 'דוח חברות', path: '/CompanyReport', icon: 'fas fa-building', subMenu: [] },
    { title: 'דוח מנויים', path: '/SubscribersWash', icon: 'fas fa-fw fa-table',subMenu: []},
    //{ title: 'דוח מנויים', path: '/SubscriberReport', icon: 'fas fa-wind', subMenu: [] },
]
