import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICompany {
company_id:number,
company_name:string,
contact_person?:string,
contact_phone?:string,
regular_price?:number,
email?:string
}

interface ICompanyData {
    list?: Array<ICompany>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ICompanyData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const CompanySlice = createSlice({
    name: "Company",
    initialState,
    reducers: {
        setCompanyList: (state, _action: PayloadAction<ICompanyData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetCompanyToInit: (state) => {
            state = initialState;
        },
        setCompanyMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setCompanyList, resetCompanyToInit, setCompanyMessage } = CompanySlice.actions;

export default CompanySlice.reducer;

