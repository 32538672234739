import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISubscribersWash {
    subscriber_id: number,
    full_name: string,
    car_number: string,
    phone: string,
    credit: number,
    date_of_wash: Date
}

interface ISubscribersWashData {
    list?: Array<ISubscribersWash>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ISubscribersWashData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const SubscribersWashSlice = createSlice({
    name: "SubscribersWash",
    initialState,
    reducers: {
        setSubscribersWashList: (state, _action: PayloadAction<ISubscribersWashData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetSubscribersWashToInit: (state) => {
            state = initialState;
        },
        setSubscribersWashMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setSubscribersWashList, resetSubscribersWashToInit, setSubscribersWashMessage } = SubscribersWashSlice.actions;

export default SubscribersWashSlice.reducer;




