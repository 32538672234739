import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setSubscribersMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addSubscribers, updateSubscribers } from "services/SubscribersService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const SubscribersForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { subscriber_id: '', full_name: '', car_number: '', phone: '', credit: '', active: '', update_subscribe: '' };
    const initialValue = action === 'edit' ? row : iValue;


    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateSubscribers(values.subscriber_id, values);
                if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setSubscribersMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setSubscribersMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addSubscribers(values);
                if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setSubscribersMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setSubscribersMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
            full_name: yup.string().required('full_name is required'),
            car_number: yup.string().required('car_number is required'),
            phone: yup.string().required('phone is required'),
            credit: yup.number().required('credit is required'),
            active: yup.boolean().required('active is required'),
            update_subscribe: yup.date().nullable(),

        }),
    });

    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} מנויים
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">הצג</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit} style={{ textAlign: 'right' }}>
                    <Form.Group>
                        <label className="form -control-label">שם מלא</label>
                        <Form.Control type="text" name="full_name" className="form-control" value={formik.values.full_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.full_name && !!formik.errors.full_name}
                            isValid={!!formik.touched.full_name && !formik.errors.full_name}
                        ></Form.Control>
                        {
                            formik.errors.full_name && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.full_name}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">מס רכב</label>
                        <Form.Control type="text" name="car_number" className="form-control" value={formik.values.car_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.car_number && !!formik.errors.car_number}
                            isValid={!!formik.touched.car_number && !formik.errors.car_number}
                        ></Form.Control>
                        {
                            formik.errors.car_number && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.car_number}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">נייד</label>
                        <Form.Control type="text" name="phone" className="form-control" value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.phone && !!formik.errors.phone}
                            isValid={!!formik.touched.phone && !formik.errors.phone}
                        ></Form.Control>
                        {
                            formik.errors.phone && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.phone}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">מס שטיפות</label>
                        <Form.Control type="number" name="credit" className="form-control" value={formik.values.credit}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.credit && !!formik.errors.credit}
                            isValid={!!formik.touched.credit && !formik.errors.credit}
                        ></Form.Control>
                        {
                            formik.errors.credit && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.credit}
                                </Form.Control.Feedback>
                            )}

                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">פעיל?</label>
                        <Form.Control type="boolean" name="active" className="form-control" value={formik.values.active}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.active && !!formik.errors.active}
                            isValid={!!formik.touched.active && !formik.errors.active}
                        ></Form.Control>
                        {
                            formik.errors.active && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.active}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">ת. עידכון</label>
                        <Form.Control type="date" name="update_subscribe" className="form-control" value={formik.values.update_subscribe}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.update_subscribe && !!formik.errors.update_subscribe}
                            isValid={!!formik.touched.update_subscribe && !formik.errors.update_subscribe}
                        ></Form.Control>
                        {
                            formik.errors.update_subscribe && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.update_subscribe}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">שמור</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

