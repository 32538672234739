import { APIService } from "services";

export const getCompanyWashList = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllCompanyWashList(pageNo,pageSize);
    }
    else{
        try {
            res = await searchCompanyWashList(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addCompanyWashList = (data) => {
return APIService.api().post(`/CompanyWashList`,data)
}
export const updateCompanyWashList = (wash_list_id,data) => {
return APIService.api().patch(`/CompanyWashList/${wash_list_id}`,data)
}
export const getAllCompanyWashList = (pageNo,pageSize) => {
return APIService.api().get(`/CompanyWashList/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneCompanyWashList = (wash_list_id) => {
return APIService.api().get(`/CompanyWashList/${wash_list_id}`)
}
export const searchCompanyWashList = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/CompanyWashList/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteCompanyWashList = (wash_list_id) => {
return APIService.api().delete(`/CompanyWashList/${wash_list_id}`)
}
