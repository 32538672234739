import React from "react";
import { useNavigate } from "react-router";
import { Row } from "react-bootstrap";
import Layout from "template";
import { MenuItems } from "template/MenuItems";
import { DashboardCard } from "./DasboardCard";

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const user_type = localStorage.getItem("userType")
  console.log('user_type:', user_type)
  if(localStorage.getItem("userType")==='employee'){
    console.log('type:',localStorage.getItem("userType"))
    navigate('/Wash');
  }
  return (
    <Layout>
      <div className="container-fluid" >
        <div className="d-sm-flex align-items-center justify-content-between mb-4" >
          <h1 className="h3 mb-0 text-gray-800" > ראשי </h1>
        </div>
        <div className="d-flex flex-column min-vh-100" >
          <Row>
            {MenuItems.map((item, i) => {
              return <DashboardCard key={`Card-${i}`} name={item.title} path={item.path} />
            })}
          </Row>
        </div>
      </div></Layout >
  );
};

