import React from "react";
import Layout from "template";
import { WashActions } from './WashActions'


export const Wash: React.FC = () => {

    return (
        <Layout>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4 heb">
                    <h1 className="h3 mb-0 text-gray-800">שטיפה מנויים</h1>
                    
                </div>
                <div className="d-flex flex-column min-vh-100">
                    <WashActions />
                </div>
            </div>
        </Layout>
    )
}