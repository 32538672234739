import { APIService } from "services";

export const getCompany = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllCompany(pageNo,pageSize);
    }
    else{
        try {
            res = await searchCompany(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addCompany = (data) => {
return APIService.api().post(`/Company`,data)
}
export const updateCompany = (company_id,data) => {
return APIService.api().patch(`/Company/${company_id}`,data)
}
export const getAllCompany = (pageNo,pageSize) => {
return APIService.api().get(`/Company/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneCompany = (company_id) => {
return APIService.api().get(`/Company/${company_id}`)
}
export const searchCompany = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/Company/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteCompany = (company_id) => {
return APIService.api().delete(`/Company/${company_id}`)
}
