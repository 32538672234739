import { APIService } from "services";

export const getSubscribersWash = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllSubscribersWash(pageNo, pageSize);
    }
    else {
        try {
            res = await searchSubscribersWash(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], totalCount: 0 }
        }
    }
    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addSubscriberswash = (data) => {
    return APIService.api().post(`/SubscribersWash`, data)
}
export const updateSubscribersWash = (action_id, data) => {
    return APIService.api().patch(`/SubscribersWash/${action_id}`, data)
}
export const getAllSubscribersWash = (pageNo, pageSize) => {
    return APIService.api().get(`/SubscribersWash/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneSubscribersWash = (action_id) => {
    return APIService.api().get(`/SubscribersWash/${action_id}`)
}
export const searchSubscribersWash = (searchKey, pageNo, pageSize) => {
    return APIService.api().get(`/SubscribersWash/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteSubscribersWash = (action_id) => {
    return APIService.api().delete(`/SubscribersWash/${action_id}`)
}
