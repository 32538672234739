import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import Users from "redux/slices/Users";
import SubscribersWash from "redux/slices/SubscriberWash";
import Subscribers from "redux/slices/Subscribers";

import CompanyWashList from "redux/slices/CompanyWashList";
import Company from "redux/slices/Company";


const rootReducer = combineReducers({ template,authToken,Users,SubscribersWash,Subscribers,CompanyWashList,Company });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

