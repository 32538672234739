import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import { Users, Subscribers, CompanyWashList, Company, SubscribersWash,  Wash } from "components";
const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}>
        </Route>
        <Route path="/register" element={<Register />}>
        </Route>
        <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />}></Route>
        <Route path="/upload" element={<AuthenticatedRoute element={<FileUpload />} />}></Route>
        <Route path="/Users"  element={<AuthenticatedRoute element={<Users />} />}></Route>
        <Route path="/Wash" element={<AuthenticatedRoute element={<Wash />} />}></Route>
        <Route path="/Subscribers" element={<AuthenticatedRoute element={<Subscribers />} />}></Route>
        <Route path="/CompanyWashList" element={<AuthenticatedRoute element={<CompanyWashList />} />}></Route>
        <Route path="/Company" element={<AuthenticatedRoute element={<Company />} />}></Route>
        <Route path="/SubscribersWash" element={<AuthenticatedRoute element={<SubscribersWash />} />}></Route>
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;

