import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Button, Card, Form } from "react-bootstrap";
import { resetCompanyToInit, setCompanyList, setCompanyWashListMessage } from "redux/actions";
import { getCompany } from "services/CompanyService";

import { useAppDispatch } from "redux/store";
import { addCompanyWashList, updateCompanyWashList } from "services/CompanyWashListService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const CompanyWashListForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { wash_list_id: 0, company_id: 0, car_number: '', note: '', price: 0, wash_date: '', worker: '' };
    const initialValue = action === 'edit' ? row : iValue;
    const CompanyData = useSelector((state: RootState) => state.Company);

    useEffect(() => {
        if (CompanyData && CompanyData.list && CompanyData.list.length === 0) {
            dispatch(resetCompanyToInit());
            getCompany(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                    dispatch(setCompanyList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                    dispatch(setCompanyWashListMessage("No Record Found For Company"));
                }
            })
        }
    })
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateCompanyWashList(values.wash_list_id, values);
                if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setCompanyWashListMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setCompanyWashListMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addCompanyWashList(values);
                if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setCompanyWashListMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setCompanyWashListMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
            company_id: yup.number().required('company_id is required'),
            car_number: yup.string().required('car_number is required'),
            note: yup.string().nullable(),
            price: yup.number().nullable(),
            wash_date: yup.date().required('wash_date is required'),
            worker: yup.string().required('worker is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize"> שטיפה חברות
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">הצג</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit} style={{ textAlign: 'right' }}>
                    <Form.Group>
                        <label className="form -control-label">חברה</label>
                        <Form.Control as="select" name="company_id" className="form-control" value={formik.values.company_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.company_id && !!formik.errors.company_id}
                            isValid={!!formik.touched.company_id && !formik.errors.company_id}
                        >
                            <option value={0}>בחר חברה </option>
                            {
                                CompanyData.list.map((item, i) => {
                                    return <option value={item.company_id} key={`Company-${i}`}>{item.company_name}</option>
                                })}
                        </Form.Control>
                        {
                            formik.errors.company_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.company_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">מס רכב</label>
                        <Form.Control type="text" name="car_number" className="form-control" value={formik.values.car_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.car_number && !!formik.errors.car_number}
                            isValid={!!formik.touched.car_number && !formik.errors.car_number}
                        ></Form.Control>
                        {
                            formik.errors.car_number && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.car_number}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">הערות</label>
                        <Form.Control type="text" name="note" className="form-control" value={formik.values.note}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.note && !!formik.errors.note}
                            isValid={!!formik.touched.note && !formik.errors.note}
                        ></Form.Control>
                        {
                            formik.errors.note && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.note}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">מחיר</label>
                        <Form.Control type="number" name="price" className="form-control" value={formik.values.price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.price && !!formik.errors.price}
                            isValid={!!formik.touched.price && !formik.errors.price}
                        ></Form.Control>
                        {
                            formik.errors.price && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.price}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">תאריך</label>
                        <Form.Control type="date" name="wash_date" className="form-control" value={formik.values.wash_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.wash_date && !!formik.errors.wash_date}
                            isValid={!!formik.touched.wash_date && !formik.errors.wash_date}
                        ></Form.Control>
                        {
                            formik.errors.wash_date && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.wash_date}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">עובד</label>
                        <Form.Control type="text" name="worker" className="form-control" value={formik.values.worker}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.worker && !!formik.errors.worker}
                            isValid={!!formik.touched.worker && !formik.errors.worker}
                        ></Form.Control>
                        {
                            formik.errors.worker && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.worker}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">שמור</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

