import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICompanyWashList {
wash_list_id:number,
company_id:number,
car_number:string,
note?:string,
price?:number,
wash_date:Date,
worker:string
}

interface ICompanyWashListData {
    list?: Array<ICompanyWashList>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ICompanyWashListData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const CompanyWashListSlice = createSlice({
    name: "CompanyWashList",
    initialState,
    reducers: {
        setCompanyWashListList: (state, _action: PayloadAction<ICompanyWashListData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetCompanyWashListToInit: (state) => {
            state = initialState;
        },
        setCompanyWashListMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setCompanyWashListList, resetCompanyWashListToInit, setCompanyWashListMessage } = CompanyWashListSlice.actions;

export default CompanyWashListSlice.reducer;

