import React, { useState } from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap"
import { addSubscribers } from "services/SubscribersService";

const getCurrentDate = () => {
    let m = new Date();
    let dateString =
        m.getUTCFullYear() + "/" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) + "/" +
        ("0" + m.getUTCDate()).slice(-2)
    return dateString
}

export const SubscribersAdd: React.FC = () => {
    let currentDate = getCurrentDate()
    const [showAdd, setShowAdd] = useState('none')
    const [message, setMessage] = useState('')
    const values = {
        full_name: '',
        car_number: '',
        phone: '',
        credit: 0,
        active: 1,
        update_subscribe: currentDate
    }

    const submitForm = async () => {
        console.log("values before --- ",values)
        addSubscribers(values).then((response) => {
            console.log('response -- ',response)
            let subAdd = `  ${values.full_name} נשמר במערכת`
            setMessage(subAdd)
            toggleForm()
            document.querySelectorAll("input").forEach(
                input => (input.value = "")
            )
        })
    }

    const toggleForm = () => {
        if (showAdd === 'inline') {
            setShowAdd('none')
        } else {
            setShowAdd('inline')
        }
    }

    return (
        <Card className="shadow mb-4">
            <Button className="btn-icon-split float-right" onClick={toggleForm}>
                <span className="icon text-white-50">
                    <i className="fas fa-add"></i>
                </span>
                <span className="text"> הוסף מנוי </span>
            </Button>
            <div style={{ textAlign: 'center', color: 'green', fontWeight: 'bold', fontSize: '16px' }}>{message}</div>
            <div style={{ display: showAdd }}>
                <Card.Body>
                    <div style={{ textAlign: 'right' }}>
                        <Container>
                            <Row>
                                <Col>
                                    <label className="form -control-label">מס רכב</label>
                                    <input type="text" name="car_number" className="form-control"
                                        onChange={(e) => values.car_number = e.target.value} />
                                </Col>
                                <Col>
                                    <label className="form -control-label">שם מלא</label>
                                    <input type="text" name="full_name" className="form-control"
                                        onChange={(e) => values.full_name = e.target.value} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label className="form -control-label">מס שטיפות</label>
                                    <input type="number" name="credit" className="form-control"
                                        onChange={(e) => values.credit = parseInt(e.target.value)} />
                                </Col>
                                <Col>
                                    <label className="form -control-label">נייד</label>
                                    <input type="text" name="phone" className="form-control"
                                        onChange={(e) => values.phone = e.target.value} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button style={{ marginTop: '32px' }} type="submit" variant="primary" onClick={() => submitForm()}>שמור</Button>
                                </Col>
                                <Col>
                                    <label className="form -control-label">פעיל?</label>
                                    <select name="active" className="form-control" value={values.active}
                                        onChange={(e) => values.active = parseInt(e.target.value)}>
                                        <option value={1}>פעיל</option>
                                        <option value={0}>לא פעיל</option>
                                    </select>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Card.Body>
            </div>
        </Card>
    );
}

