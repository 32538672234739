import { calendarFormat } from "moment";
import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col, Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { findByCarNumber, findByPhone, updateCredit, updateRenew } from "services/SubscribersService";
import { addSubscriberswash } from "services/SubscribersWashService";
import { Data } from './Data'
import { SubscribersAdd } from './table'

export const WashActions: React.FC = () => {

    const values = {
        subscriber_id: 0,
        car_number: '',
        date_of_wash: new Date().toISOString().split('T')[0],
    }
    const credit_values = {
        date_of_wash: new Date().toISOString().split('T')[0],
        credit: 0,
    }
    const upgrade_credit = {
        credit: 0,
    }

    const [credit, setCredit] = useState<number>(0)
    const [currentCredit, setCurrentCredit] = useState<number>(0)
    const [action, setAction] = useState('')
    const [show, setShow] = useState('none')
    const [showAdd, setShowAdd] = useState('none')
    const [showApproved, setShowApproved] = useState('none')
    const [showUpdateCredit, setShowUpdateCredit] = useState('none')
    const [dis, setDis] = useState<boolean>(false)
    const [subId, setSubId] = useState(null)
    const [data, setData] = useState([]);
    const [phone, setPhone] = useState('');
    const [car, setCar] = useState('');
    const [fullName, setFullName] = useState('');

    const getData = (searchKey, search) => {
        if (search === 'car') {
            findByCarNumber(searchKey).then((response) => {
                console.log("response.data:", response.data)
                setData(response.data)
                setSubId(response.data.subscriber_id)
                setPhone(response.data.phone)
                setFullName(response.data.full_name)
                setCurrentCredit(response.data.credit)
                if(response.data.credit === 0){
                    subscriberUpdateCredit()
                    setAction('למנוי אין יותר שטיפות אנא עדכן שטיפות להמשך התהליך')
                }
                setShow('inline')
                setShowApproved('inline')
                localStorage.setItem('wash_sub_id', String(response.data.subscriber_id))
            })
        }
        if (search === 'phone') {
            findByPhone(searchKey).then((response) => {
                setData(response.data)
                setSubId(response.data.subscriber_id)
                setCar(response.data.car_number)
                setCurrentCredit(response.data.credit)
                if(response.data.credit === 0){
                    subscriberUpdateCredit()
                    setAction('למנוי אין יותר שטיפות אנא עדכן שטיפות להמשך התהליך')
                }
                setShow('inline')
                setShowApproved('inline')
                localStorage.setItem('wash_sub_id', String(response.data.subscriber_id))
            })
        }
        setAction('')
    }

    const searchType = (e) => {
        e.preventDefault()
        if (car.length > 0) {
            getData(car, 'car')
        }
        if (phone.length > 0) {
            getData(phone, 'phone')
        }
    }

    const calendarFormat = () => {
        setPhone("")
        setCar("")
    }
    const WashApproved = async (e) => {
        e.preventDefault()
        if(currentCredit > 0){
            values.car_number = car
            values.subscriber_id = subId
            values.date_of_wash = new Date().toISOString().split('T')[0]
            console.log('values:', values)
            addSubscriberswash(values).then((response) => {
                console.log("addSubscribersWash: ", response.data)
                updateCredit(subId).then((response) => {
                    console.log("updateCredit: ", response.data)
                    let crd = currentCredit - 1
                    setAction(`${crd} - מספר השטיפות הנותרות`)
                    setShow('none')
                    setPhone("")
                    setCar("")
                    setSubId(null)
                    setShowApproved('none')
                    setShowUpdateCredit('none')
                })
            })
        }else{
            setAction('למנוי אין יותר שטיפות אנא עדכן שטיפות להמשך התהליך')
        }
    }

    const UpdateCredit = () => {
        let approvedCredit = currentCredit + credit
        if(approvedCredit > 15){
            approvedCredit = 15
        }   
        upgrade_credit.credit = approvedCredit
        updateRenew(subId, upgrade_credit).then((response) => {
            console.log(response)
            getData(car, 'car')
            setAction('מספר שטיפות המנוי עודכן ניתן עד 15 בלבד')
            setDis(true)
            setCredit(0)
            setShowUpdateCredit('none')
        })
    }

    const subscriberUpdateCredit = () => {
        if (subId > 0) {
            setShowUpdateCredit('inline')
        } else {
            setAction('ראשית יש לבצע חיפוש מנוי על מנת לעדכן שטיפות')
        }
    }
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3" style={{ textAlign: 'right' }}>
                <Row className="mb-3">
                    <Col sm={6}>
                        <Button className="btn-icon-split float-right" onClick={() => setShowAdd('inline')}>
                            <span className="icon text-white-50">
                                <i className="fas fa-add"></i>
                            </span>
                            <span className="text"> הוספת מנוי </span>
                        </Button>
                    </Col>
                    <Col sm={3}>
                        <Button className="btn-icon-split float-right" onClick={() => subscriberUpdateCredit()}>
                            <span className="icon text-white-50">
                                <i className="fas fa-add"></i>
                            </span>
                            <span className="text"> חידוש מנוי </span>
                        </Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Container fluid>
                    <Row className="mb-3">
                        <Col sm={6} style={{ textAlign: 'right' }}>
                            <Button variant="primary" onClick={(e) => searchType(e)}> התחל חיפוש </Button>
                        </Col>
                        <Col sm={6}>
                            <input placeholder="חפש לפי רכב" value={car} style={{ textAlign: 'right' }} type="text" onChange={(e) => setCar(e.target.value)} name="car_number" className="form-control" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        
                        <Col sm={3} style={{ textAlign: 'right' }}>
                            <Button style={{ display: showApproved }} variant="success" onClick={(e) => WashApproved(e)}>אשר שטיפה</Button>
                        </Col>
                        <Col sm={3} style={{ textAlign: 'right' }}>
                            <Button  variant="secondary" onClick={()=> calendarFormat()}>נקה שדות</Button>
                        </Col>
                        <Col sm={6}>
                            <input placeholder="חפש לפי טלפון" value={phone} style={{ textAlign: 'right' }} type="text" onChange={(e) => setPhone(e.target.value)} name="phone" className="form-control" />
                        </Col>
                    </Row>
                    {data ? <>
                            <Row style={{ display: show }}>
                                <Col sm={12}>
                                    <Data subscriber={data} />
                                </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: showUpdateCredit, textAlign: "right" }}>
                                <Col></Col>
                                <Col>
                                    <label style={{ color: '#33333', fontWeight: 'bold' }}>במידה והלקוח חידש או הרחיב מנוי אנא מלא כמה להוסיף אחרת השאר ריק</label>
                                </Col>
                                <Col>
                                    <Button variant="primary" disabled={dis} onClick={() => UpdateCredit()}>הוסף שטיפות</Button> <input type="number" onChange={(e) => setCredit(parseInt(e.target.value))} style={{ width: "50px" }} />
                                </Col>
                            </Row>  
                        </> : <label style={{ color: 'red', fontWeight: 'bold' }}>'לא נמצא לקוח כזה אנא נסה שנית'</label>}
                        <Row>
                        <Col sm={12} style={{ textAlign: 'right' }}>
                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: '16px' }}>{action}</label>
                        </Col>
                    </Row>
                </Container>

            </Card.Body>
            <Card.Body>
                <Row>
                    <Col style={{ display: showAdd }}>
                        <SubscribersAdd />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

