import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setUsersMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addUsers, updateUsers } from "services/UsersService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const UsersForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { user_id: 0, user: '', password: '', display_name: '', user_type: '' };
    const initialValue = action === 'edit' ? row : iValue;


    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateUsers(values.user_id, values);
                if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setUsersMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUsersMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addUsers(values);
                if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setUsersMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUsersMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
            user: yup.string().required('user is required'),
            password: yup.string().required('password is required'),
            display_name: yup.string().required('display_name is required'),
            user_type: yup.string().required('user_type is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} משתמשים
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">הצג</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit} style={{textAlign:'right'}}>
                    <Form.Group>
                        <label className="form -control-label">משתמש</label>
                        <Form.Control type="text" name="user" className="form-control" value={formik.values.user}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.user && !!formik.errors.user}
                            isValid={!!formik.touched.user && !formik.errors.user}
                        ></Form.Control>
                        {
                            formik.errors.user && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.user}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">סיסמה</label>
                        <Form.Control type="text" name="password" className="form-control" value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.password && !!formik.errors.password}
                            isValid={!!formik.touched.password && !formik.errors.password}
                        ></Form.Control>
                        {
                            formik.errors.password && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.password}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">שם תצוגה</label>
                        <Form.Control type="text" name="display_name" className="form-control" value={formik.values.display_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.display_name && !!formik.errors.display_name}
                            isValid={!!formik.touched.display_name && !formik.errors.display_name}
                        ></Form.Control>
                        {
                            formik.errors.display_name && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.display_name}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">סוג</label>
                        <Form.Control type="text" name="user_type" className="form-control" value={formik.values.user_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.user_type && !!formik.errors.user_type}
                            isValid={!!formik.touched.user_type && !formik.errors.user_type}
                        ></Form.Control>
                        {
                            formik.errors.user_type && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.user_type}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">שמור</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

